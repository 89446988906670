class ZIDXAccountPaymentPlanEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPaymentPlanEditContainer";
    }
    getMatchURL(){
        return "/account/payment-plans/edit";
    }
    render(){
        // console.log("payment plan edit");
    }
}